/**
 * Candidate information form.
 */

import React, { useState, useRef } from 'react';
import InputMask from 'react-input-mask';
import Autocomplete from 'react-google-autocomplete';
import Dropdown from './Dropdown';
import { getCookie } from '../util/pagetools';

// Create a forward ref to enable accessing ref for focusing
const PhoneInput = React.forwardRef((props, ref) => (
  <InputMask
    mask="(999) 999-9999"
    name={props.name}
    id={props.name}
    value={props.value}
    className="form-control"
    aria-required="true"
    ref={ref}
    onChange={props.onChange}
    onBlur={props.onChange}>
  </InputMask>
));

const formValidators = {
  firstname: /(.|\s)*\S(.|\s)*/,
  lastname: /(.|\s)*\S(.|\s)*/,
  phone: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
  email: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
  autocomplete: /(.|\s)*\S(.|\s)*/
};

const reqIndicator = <span title="This field is required.">*</span>;
const placesAPIkey = 'AIzaSyCMEV1mP_nO_tKOimhKlk98fQCrtoo0Gy0';
const UTM = 'UTM_INFO';
let formInteracted = false;

export default function PatientForm(props) {
  const site = props.sitedata;
  const COUNTRY_LOOKUPS = ['us'];
  const [patientInfo, setPatientInfo] = useState({
    firstname: '',
    lastname: '',
    gender: '',
    phone: '',
    email: '',
    howDidYouHearAboutUs: '',
    qualified: (props.isQualified ? 1 : 0),
    language: site.language
  });

  const [errors, setErrors] = useState({
    firstname: true,
    lastname: true,
    phone: true,
    email: true,
    autocomplete: true
  });
  const [userAddress, setUserAddress] = useState({});
  // Define field refs for setting focus
  const fieldRefs = {
    firstname: useRef(null),
    lastname: useRef(null),
    phone: useRef(),
    email: useRef(null),
    autocomplete: useRef(null)
  };

  function onSubmit(e) {
    e.preventDefault();
    formInteracted = true;
    // Validate here!
    const fieldErrors = validateForm();
    const inValidFields = Object.keys(fieldErrors).filter(key => fieldErrors[key]);
    if (inValidFields.length>0) {
      const firstError = fieldRefs[inValidFields[0]].current;
      if (firstError.focus) firstError.focus();
      else {
        // Unable to focus
        if (firstError.getInputDOMNode) firstError.getInputDOMNode().focus();
      }
      return;
    }
    const payload = Object.assign({}, patientInfo, userAddress)
    if (props.onSubmit) {
      const generalInfo = {
        utm: getCookie(UTM),
        site: site.siteurl
      };
      props.onSubmit(Object.assign(payload, generalInfo));
      formInteracted = false; // reset interaction flag
    }
    // Clear the autocomplete field
    // if (fieldRefs.autocomplete) fieldRefs.autocomplete.current.value = '';
  }

  function handleInputChange(event) {
    setPatientInfo({
      ...patientInfo,
      [event.target.name]: event.target.value
    });
  }

  function validateInput(event) {
    setPatientInfo({
      ...patientInfo,
      [event.target.name]: event.target.value
    });
    const validator = formValidators[event.target.name];
    const isFieldOk = (validator ? validator.test(event.target.value) : true);
    setErrors({ ...errors, [event.target.name]: !isFieldOk });
    // console.log(`Errors at field ${event.target.name} ->`, errors);
  }

  function onPlaceChange(place, inputRef) {
    if (place.geometry) {
      setUserAddress(extractAddressFields(place));
    } else {
      // User entered the name of a Place that was not suggested and pressed ENTER, or the Place Details request failed.
      inputRef.value = '';
      setUserAddress({});
    }
  }

  // Runs onChange and onBlur for address field
  function validatePlaceInput(event) {
    const isFieldOk = formValidators.autocomplete.test(event.target.value);
    setErrors({ ...errors, 'autocomplete': !isFieldOk });
    if (!isFieldOk) {
      setUserAddress({});
    }
    // console.log(`Validate ${event.target.name} val="${event.target.value}" currPlace="${userAddress.full}" valid=${isFieldOk}, errors:`, Object.keys(errors).filter(key => errors[key]));
  }

  function showError(field) { return (errors[field] && formInteracted); }

  function validateForm() {
    // Collect an array of field names and their statuses
    const updatedErrors = Object.keys(formValidators).reduce((status, field) => {
      const fieldvalue = (field==='autocomplete' ? (userAddress.full || '') : patientInfo[field]);
      const validator = formValidators[field];
      const isFieldOk = (validator ? validator.test(fieldvalue) : true);
      // console.log(`VF: ${field} = "${fieldvalue}" ..`, isFieldOk);
      status[field] = !isFieldOk;
      return status;
      }, {});
    setErrors(updatedErrors);
    return updatedErrors;
  }

  return (
    <form className="patient-form">
      <div className="row">
        <div className="col-sm-6 form-group">
          <label htmlFor="firstname">{site.patientFields.firstname} {reqIndicator}</label>
          <input type="text" name="firstname" id="firstname" className="form-control" aria-required="true"
                 onChange={validateInput} onBlur={validateInput} value={patientInfo.firstname} ref={fieldRefs.firstname} />
          <p className={`text-red ${showError('firstname') ? 'vis' : 'hid'}`}>First name must not be blank</p>
        </div>
        <div className="col-sm-6 form-group">
          <label htmlFor="lastname">{site.patientFields.lastname} {reqIndicator}</label>
          <input type="text" name="lastname" id="lastname" className="form-control" aria-required="true"
                 onChange={validateInput} onBlur={validateInput}value={patientInfo.lastname} ref={fieldRefs.lastname} />
          <p className={`text-red ${showError('lastname') ? 'vis' : 'hid'}`}>Last name must not be blank</p>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6 form-group">
          <label htmlFor="phone">{site.patientFields.phone} {reqIndicator}</label>
          <PhoneInput name="phone" onChange={validateInput} value={patientInfo.phone} ref={fieldRefs.phone} />
          <p className={`text-red ${showError('phone') ? 'vis' : 'hid'}`}>Please enter a valid phone number</p>
        </div>
        <div className="col-sm-6 form-group">
          <label htmlFor="email">{site.patientFields.email} {reqIndicator}</label>
          <input type="text" name="email" id="email" className="form-control" aria-required="true"
                 onChange={validateInput} value={patientInfo.email} ref={fieldRefs.email}/>
          <p className={`text-red ${showError('email') ? 'vis' : 'hid'}`}>Please enter a valid email</p>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 form-group">
          <label htmlFor="autocomplete">{site.patientFields.address} {reqIndicator}</label>
          <Autocomplete name="autocomplete"
                    apiKey={placesAPIkey}
                    options={{ types: ['address'], componentRestrictions: {country: COUNTRY_LOOKUPS} }}
                    className="form-control"
                    id="autocomplete"
                    aria-required="true"
                    onPlaceSelected={onPlaceChange}
                    onChange={validatePlaceInput}
                    onBlur={validatePlaceInput}
                    ref={fieldRefs.autocomplete} />
          <p className={`text-red ${showError('autocomplete') ? 'vis' : 'hid'}`}>Please select a valid address</p>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6">
          <div className="form-group">
            <Dropdown name="howDidYouHearAboutUs"
              items={site.patientFields.referredby}
              defaultItem={site.patientFields.referredbyDefault}
              ddStyle="dropdown form-control"
              responder={handleInputChange} />
          </div>
        </div>
      </div>
      <div className="btn-container">
        <button className="form-btn btn-med-on-white" onClick={onSubmit}>{props.formAction}</button>
      </div>
    </form>
  );
};


function addressParser(place) {
  return function(part, useShortname) {
    const addressPart = place.address_components.find(comp => comp.types.includes(part));
    return (addressPart ? (useShortname ? addressPart.short_name : addressPart.long_name) : '');
  };
}

function extractAddressFields(place) {
  const lookup = addressParser(place);
  return {
    full: place.formatted_address,
    thoroughfare: `${lookup('street_number')} ${lookup('route')}`,
    premise: lookup('premise'),
    city: lookup('locality'),
    state: lookup('administrative_area_level_1', true),
    zip: lookup('postal_code'),
    country: lookup('country', true),
    lat: place.geometry.location.lat(),
    lng: place.geometry.location.lng()
  };
}
