/* A content section comprised only of a header and one or
 * more paragraphs of text.
 * Supported props: src
*/

import React from 'react';
import { css } from '../util/pagetools';

export default function BasicSection(props) {
  const cfg = props.src;
  const pStyle = cfg.theme || 'text-black';

  return (
    <div id={cfg.id} className={css('basic-section', cfg.theme)}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2">
            <h1 className={css('section-header', cfg.headerclass)}><span>{cfg.header || cfg.title}</span></h1>
            <h3>Study Location: {cfg.subhead}</h3>
            {renderParagraphs(cfg.text, pStyle, cfg.displayAs)}
          </div>
        </div>
      </div>
    </div>
  );
};

function renderParagraphs(parags, styling, displayType) {
  if (displayType==='list') {
    return <ul>{parags.map((item, index) => <li key={index} className={styling}>{item}</li> )}</ul>;
  }
  else if (displayType==='faq') { return renderOrderedFaq(parags, styling); }
  else if (displayType==='ufaq') { return renderUnorderedFaq(parags, styling); }
  if (!Array.isArray(parags)) return <p>?</p>;
  return parags.map((item, index) => <p key={index} className={styling}>{item}</p> );
}

function renderUnorderedFaq(parags, styling) {
    return parags.map((item, index) => {
      const [q, ans] = item.split('?');
      return (
        <div key={index}>
          <p className={`faq ${styling}`}>{q+'?'}</p>
          <p className={styling}>{ans}</p>
        </div>
      );
    });
}

function renderOrderedFaq(parags, styling) {
  return (
    <ol>
      {parags.map((item, index) => {
        const [q, ans] = item.split('?');
        return (
          <li key={index}>
            <p className={`faq`}>{q+'?'}</p>
            {faqAnswer(ans, styling)}
          </li>
        );
      })}
    </ol>
  );
}

// Support multiple paragraphs by parsing by delimiter (semi-colon).
function faqAnswer(text, styling) {
  if (text.indexOf(';')>=0) return text.split(';').map((parag,idx) => <p key={`faqp-${idx}`} className={styling}>{parag}</p>);
  return <p className={styling}>{text}</p>;
}
